import React, { Component } from "react"
import About from "./about/About";



class Main extends Component{
  render() {
    return (
      <>
        <About />
      </>
    )
  }
}


export default Main;