import React from "react"
import PageTitle from "../page_title/PageTitle";
import Content from "./Content";


export default function Resume() {


  return (
    <>
      <PageTitle name={"Resume"} />
    <section className="resume">
      <Content />
      </section>
    </>
    )

}